import React, { useState } from 'react';
import "./Wregister.scss";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { countryCodes } from './countryCodes'; // adjust the path accordingly if you place it in a different directory
import HANUMANJI from "../../images/logo.webp";

const SERVER_ENDPOINT = process.env.REACT_APP_SERVER_ENDPOINT;

function Signup() {
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Combine the country code and phone number
    const fullPhoneNumber = countryCode + phoneNumber;

    try {
      // Check if phone number already exists
      const checkResponse = await axios.post(`${SERVER_ENDPOINT}/api/checkPhoneNumber`, { phoneNumber: fullPhoneNumber });

      // Specific handling for 409 status
      if (checkResponse.status === 409) {
        alert(checkResponse.data.message);
        return;
      }

      // Proceed to create a checkout session
      const sessionRes = await axios.post(`${SERVER_ENDPOINT}/api/createCheckoutSession`, { phoneNumber: fullPhoneNumber });
      const sessionId = sessionRes.data.id;

      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error('Error redirecting to Stripe Checkout:', error);
      }
      else {
      // If registration is successful and there is no error, navigate to the success page
      navigate('/select-your-page'); // Replace '/select-your-page' with the actual path you want to navigate to
    }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert(error.response.data.message);
      } else {
        console.error('Error occurred:', error);
        alert('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="signup">
      <div className="container">
            <div className="logoContainer">
              <div className="logo"><img src={HANUMANJI} alt="Logo" className="logoImage" /></div>
                <div className="logoText">
                  <span className="maintitle" >Pavanputra</span>
                </div>
            </div>
        <div className="formContainer">
          <form className='form-input' onSubmit={handleSubmit}>
            <div className="inputGroup">
              <select
                value={countryCode}
                onChange={handleCountryCodeChange}
                required
                className="countryCodeInput"
              >
                <option value="" disabled>Select Country Code</option>
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>
              <input
                type="tel"
                placeholder='Enter your phone number'
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                required
                className="phoneNumberInput"
              />
            </div>
            <p className="note">Please enter an accurate country code and phone number.</p>
            <button type="submit" className='button'>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
