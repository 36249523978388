import React, { useState } from 'react';
import WHATSAPP1 from '../../images/GIF 1.gif';
import WHATSAPP2 from '../../images/GIF 2.gif';
import WHATSAPP3 from '../../images/GIF 3.gif';
import './Block1.scss';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Slider = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    {
      src: WHATSAPP1,
      title: 'Basic Chatbot',
      subtext: 'Up and Running',
      version: 'BASIC VERSION',
      points: [
        'Exclusive text chat for subscribers',
        'User-friendly models like text-babbage-001 and text-ada-001',
        'Free text-based gym and fitness advice',
        'Engage in text-only fitness chats',
        'Activate chat with your subscription',
      ],
    },
    {
      src: WHATSAPP2,
      title: 'Calorie Calculator',
      subtext: 'Basic Chatbot + Calorie Calculator',
      version: 'STANDARD VERSION',
      points: [
        'Photo calorie counting for your meals',
        '24/7 text conversations about gym and fitness',
        'VIP chat access for subscribers',
        'Powered by GPT-3 for top-notch responses',
        'Join the fitness conversation and access calorie counting',
      ],
    },
    {
      src: WHATSAPP3,
      title: 'Posture Corrector',
      subtext: 'Premium Chatbot + Posture Corrector',
      version: 'PREMIUM VERSION',
      points: [
        'Video form check with advanced ML models',
        'Snap meal photos for calorie counting',
        'Upgrading to offer the ultimate chatbot experience',
        'Engage in text conversations about gym and fitness',
        'Access text-based fitness chats with your subscription',
      ],
    },
  ];

  const nextSlide = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  return (
    <div className="slider">
      {/* Container Version */}
      <div className="container">
        <div className="left">
          <button className="slider-button prev-button" onClick={prevSlide}>
            PREVIOUS
          </button>
        </div>
        <div className="mid">
          <div className="content">
            <div className="version-div">{images[currentImage].version}</div>
            <div className="image-container">
              <img
                src={images[currentImage].src}
                alt={images[currentImage].title}
              />
            </div>
            <div className="description">
              <div className="image-title">{images[currentImage].title}</div>
              <div className="subtext-div">{images[currentImage].subtext}</div>
              <ul className="image-points">
                {images[currentImage].points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="indicator-buttons">
            {images.map((image, index) => (
              <button
                key={index}
                className={`indicator-button ${
                  index === currentImage ? 'active' : ''
                }`}
                onClick={() => setCurrentImage(index)}
              />
            ))}
          </div>
        </div>
        <div className="right">
          <button className="slider-button next-button" onClick={nextSlide}>
            NEXT
          </button>
        </div>
      </div>

      {/* Mobile Version */}
      <div className="mobile-version">
        {/* Left div */}
        <div className="left">
          <button className="slider-button prev-button" onClick={prevSlide}>
            <NavigateBeforeIcon /> {/* Left arrow icon */}
          </button>
        </div>
        {/* Content div */}
        <div className="mid">
          <div className="content">
            <div className="top">{images[currentImage].version}</div>
            <div className="middle">
              <img
                src={images[currentImage].src}
                alt={images[currentImage].title}
              />
            </div>
            <div className="bottom">
              <div className="image-title">{images[currentImage].title}</div>
              <div className="subtext-div">{images[currentImage].subtext}</div>
              <ul className="image-points">
                {images[currentImage].points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="indicators">
            {images.map((image, index) => (
              <button
                key={index}
                className={`indicator-button ${
                  index === currentImage ? 'active' : ''
                }`}
                onClick={() => setCurrentImage(index)}
              />
            ))}
          </div>
        </div>
        {/* Right div */}
        <div className="right">
          <button className="slider-button next-button" onClick={nextSlide}>
            <NavigateNextIcon /> {/* Right arrow icon */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Slider;
