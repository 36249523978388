// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBSlGG4zyzk9JXyP20FKTOI6x5pH7x_qe8",
  authDomain: "pavanputra-main.firebaseapp.com",
  projectId: "pavanputra-main",
  storageBucket: "pavanputra-main.appspot.com",
  messagingSenderId: "873612958183",
  appId: "1:873612958183:web:f62f18da57809a60550542",
  measurementId: "G-1QBZ0RJ4YW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
