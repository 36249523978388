import React from 'react';
import "./Footer.scss";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll'; // Import animateScroll
import HANUMANJI from "../../images/logo.webp";
import INSTAGRAM from "../../images/instagram.png";
function Footer() {
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };

  return (
    <div className="footer">
      <div className="container">
        <hr />
        <div className="upper-half">
          <div className="logo-section">
            <img src={HANUMANJI} alt="" className="logoimage"/>
            <div className="logo-name">
              <span>THE PAVANPUTRA</span>
            </div>
          </div>

          <div className="links-section">
            <ul className="links-list">
              <li><Link to="/version" className="link" onClick={scrollToTop}>Version</Link></li>
              <li><Link to="https://careers.pavan-putra.com/" className="link" onClick={scrollToTop}>Career</Link></li>
            </ul>
            <ul className="links-list">
              <li><Link to="/about" className="link" onClick={scrollToTop}>About Us</Link></li>
              <li><Link to="/support" className="link" onClick={scrollToTop}>Support</Link></li>
            </ul>
            <ul className="links-list">
              <li><Link to="/terms" className="link" onClick={scrollToTop}>Terms and Conditions</Link></li>
              <li><Link to="/privacy" className="link" onClick={scrollToTop}>Privacy and Policy</Link></li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="lower-half">
          <div className="left-section">
            <p>Pavanputra © 2023 LLC</p>
          </div>
          <div className="right-section">
            <img src={INSTAGRAM} alt="instagram" className="social" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
