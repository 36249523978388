import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import "./Block4.scss"
import ClearIcon from '@mui/icons-material/Clear';
function CONTAINER3() {
  return (
    <div className="block4">
      <div className='wrapper'>
        <div className="pricing">
          <h1 className='pricing-text'>PRICING</h1>
        </div>
        <div className="plans">
          <div className='plan1'>
            <span className="basic">Basic</span>
            <h1>FREE</h1>
            <ul>
              <li><CheckIcon /> Basic Chatbot</li>
              <li className="notavaliable"><ClearIcon /> Calorie Counter</li>
              <li className="notavaliable"><ClearIcon /> Video based Posture Corrector</li>
              <li><CheckIcon /> 30 Days free</li>
              <li><CheckIcon /> 100 Message Credits</li>
              <li><CheckIcon /> 75,000 Token Limit</li>
              
            </ul>
            <button><Link to="https://dashboard.pavan-putra.com" className="link">Get Started Free</Link></button>
          </div>

          <div className='plan2'>
            <span className="standard">Standard</span>
            <h1>FREE</h1>
            <ul>
              <li><CheckIcon /> Basic Chatbot</li>
              <li><CheckIcon /> Calorie Counter</li>
              <li className="notavaliable"><ClearIcon /> Video based Posture Corrector</li>
              <li><CheckIcon /> Unlimited Days of Access</li>
              <li><CheckIcon /> 1,000 Message Credits</li>
              <li><CheckIcon /> 210,000 Token Limit</li>
            </ul>
            <button><Link to="https://dashboard.pavan-putra.com" className="link">Get Started</Link></button>
          </div>

          <div className='plan3'>
            <span className="premium">Premium</span>
            <h1>FREE</h1>
            <ul>
              <li className="highlighting"><CheckIcon /> Premium Chatbot</li>
              <li className="highlighting"><CheckIcon /> Calorie Counter</li>
              <li className="highlighting"><CheckIcon /> Video based Posture Corrector</li>
              <li><CheckIcon /> Unlimited Days of Access</li>
              <li><CheckIcon /> 5,000 Message Credits</li>
              <li><CheckIcon /> 810,000 Token Limit</li>
            </ul>
            <button><Link to="https://dashboard.pavan-putra.com" className="link">Get Started</Link></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CONTAINER3;
