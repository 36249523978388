import React from 'react';
import './Block2.scss';

function Block2() {
  return (
    <div className='block2'>
      <div className='title'>FEATURES</div>
      <div className='boxes'>
        <div className='box'>
          <p>24/7 AVALIABILITY</p>
        </div>
        <div className='box'>
          <p>PERSONALIZED</p>
          <p>WORKOUT</p>
          <p>PLAN</p>
        </div>
        <div className='box'>
          <p>PROGRESS</p>
          <p>TRACKER</p>
        </div>
        <div className='box'>
          <p>MOTIVATIONAL</p>
          <p>SUPPORT</p>
        </div>
        <div className='box'>
          <p>ADAPTIVE</p>
          <p>TRAINING</p>
        </div>
      </div>
    </div>
  );
}

export default Block2;
