// Block3.jsx
import React from 'react';
import './Block3.scss';
import {Link} from "react-router-dom";
function Block3() {
  return (
    <div className='block3'>
      <div className='line'>WHAT ARE YOU WAITING FOR</div>
      <div className='line2'>JOIN IN ALREADY !!!</div>
      <button className='join-button'>
        <Link to="https://dashboard.pavan-putra.com" className="link">Get Started!</Link>
      </button>
      {/* <hr className='line-divider' /> */}
    </div>
  );
}

export default Block3;
