import React from 'react';
import { Link } from 'react-router-dom';
import "./support.scss";
import HANUMANJI from "../../images/logo.webp";

function Support() {
  return (
    <div className="support">
      <div className="container">
        <div className="logoContainer">
          <img src={HANUMANJI} alt="" className="logoImage" />
          <div className="logoText">
            <span className="maintitle" data-text="Pavanputra">Pavanputra</span>
          </div>
        </div>

        <div className="content">
          <h1>Customer Support</h1>
          <p>
            Welcome to Pavanputra LLC's Customer Support. We are here to assist you with any questions, concerns, or issues you may have while using our Chatbot.
          </p>

          <h1>Contact Us</h1>
          <p>
            If you require assistance, have feedback, or need clarification, please don't hesitate to contact us at <a href="mailto:thepavanputra@gmail.com">thepavanputra@gmail.com</a>. Our support team is dedicated to ensuring your experience with Pavanputra is seamless and enjoyable.
          </p>
        </div>

        <div className="connectButton">
          <button className="connectbutton">
            <Link to="https://dashboard.pavan-putra.com" className="link">Connect Whatsapp</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Support;
