import React from 'react';
import "./CancelPage.scss"
import HANUMANJI from "../../images/logo.webp";

function CancelPage() {
  // Email address
  const emailAddress = 'info@pavan-putra.com';

  // Function to open the default email client with a pre-filled email
  const sendEmail = () => {
    const subject = 'Cancellation Inquiry'; // You can customize the email subject
    const mailtoURL = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoURL;
  };

  return (
    <div className="cancel">
      <div className="container">
            <div className="logoContainer">
              <div className="logo"><img src={HANUMANJI} alt="Logo" className="logoImage" /></div>
                <div className="logoText">
                  <span className="maintitle" >Pavanputra</span>
                </div>
            </div>
        <div className="description">
          <h2>Payment Canceled</h2>
          <p>Your payment was canceled.</p>

          {/* Button to open default email client with a pre-filled email */}
          <button className="cancel-button" onClick={sendEmail}>
            Contact Us via Email
          </button>
        </div>
      </div>
    </div>
  );
}

export default CancelPage;
