import { createContext, useEffect, useState, useContext } from "react";
import { 
    // getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from '../firebase.js';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) { // Corrected the component name to "UserAuthContextProvider"
    const [user, setUser] = useState(null); // Initialize user state with null

    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }
    function logOut() {
        return signOut(auth);
    }
    function googleSignIn() {
        const googleAuthProvider = new GoogleAuthProvider();
        return signInWithPopup(auth, googleAuthProvider);
      }

      function setUpRecaptha(number) {
        const recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
          size: "invisible", // You can adjust the size according to your needs
        });
        signInWithPhoneNumber(auth, number, recaptchaVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            console.log("Phone authentication initiated. Waiting for OTP...");
          })
          .catch((error) => {
            console.error("Error setting up phone authentication:", error.message);
            throw error;
          });
      }
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return <userAuthContext.Provider value={{ user, signUp, logIn, logOut, googleSignIn, setUpRecaptha }}>
        {children}
    </userAuthContext.Provider>;
}

export function useUserAuth() {
    return useContext(userAuthContext);
}
