import React, { useState } from 'react';
import "./Navbar.scss";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi';
import { animateScroll as scroll } from 'react-scroll'; // Import animateScroll
import HANUMANJI from "../../images/logo.webp";

function Navbar() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };

  return (
    <div className="navbar">
      <div className="container">
        <div className="logo">
          <img className="logoimage" src={HANUMANJI} alt="" />
        </div>
        <div className="hamburger-menu">
          <button className="hamburger-menu" onClick={() => setShowMediaIcons(!showMediaIcons)}>
            <GiHamburgerMenu />
          </button>
        </div>
        <div className={showMediaIcons ? 'navlinks active' : 'navlinks'}>
          <ul>
            <li>
              <Link to="/" className='link' onClick={scrollToTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className='link' onClick={scrollToTop}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/version" className='link' onClick={scrollToTop}>
                Version
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
