import React from 'react';
import { Link } from 'react-router-dom';
import './paymentgateway.scss';
import HANUMANJI from "../../images/logo.webp";

function PaymentGateway() {
  const stripeCheckoutUrl = "https://buy.stripe.com/cN2fZHaTf9K43io8wx"; // Live

  return (
    <div className="pageContainer">
      <div className="formContainer">
        <div className="formWrapper">
          <div className="logoContainer">
            <img src={HANUMANJI} alt="" className="logoImage" />
            <div className="logoText">
              <span className="logo">Pavanputra</span>
            </div>
          </div>

          <div className="subtitleContainer">
            <div className="subtitle">
              Complete your payment and get access to the power 
            </div>
            <div className="subtitle">of Pavanputra on WhatsApp for 30 days free of cost</div>
          </div>

          <div className="button-Container">
            <a href={stripeCheckoutUrl} className="payment-Button">Pay now</a>
          </div>

          <div className="changeNumberContainer">
            <span>
              Want to change the number or page not refreshing?{' '}
              <Link to="/whatsappregister" className="link">Click here</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentGateway;
