import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  // Route,
} from "react-router-dom";
import Navbar from "./components/navbar/Navbar.jsx";
import Footer from "./components/footer/Footer.jsx";
import Home from "./pages/home/home";
import Register from "./pages/Wregister/Wregister.jsx";
import Payment from "./pages/paymentgateway/paymentgateway.jsx";
import About from "./pages/about/About.jsx";
import Support from "./pages/support/Support.jsx";
import Privacypolicy from "./pages/Privacypolicies/Privacypolicies.jsx";
import Termsandconditions from "./pages/termsandconditions/Termsandconditons.jsx";
import Login from "./pages/login/Login.jsx";
import Whatsapp from "./pages/Wconnect/Wconnect.jsx";
import { UserAuthContextProvider } from "./context/UserAuthContext.jsx";
import Version from "./pages/version/Version.jsx";
import SuccessPage from "./pages/success/SuccessPage.jsx";
import CancelPage from "./pages/cancel/CancelPage.jsx";
import Selecturplan from "./pages/selecturplan/Selecturplan.jsx";
function App() {
  const Layout = () => {
    return (
      <div className="app">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/whatsappregister",
          element: <Register />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/payment",
          element: <Payment />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/support",
          element: <Support />,
        },
        {
          path: "/privacy",
          element: <Privacypolicy />,
        },
        {
          path: "/terms",
          element: <Termsandconditions />,
        },
        {
          path: "/connectwhatsapp",
          element: <Whatsapp />,
        },
        {
          path: "/version",
          element: <Version />,
        },
        {
          path: "/select-your-plan",
          element: <Selecturplan />,
        }
      ],
    },
    {
      path: "/success", // Route for the success page
      element: <SuccessPage />,
    },
    {
      path: "/cancel", // Route for the cancel page
      element: <CancelPage />,
    },
  ]);

  return (
    <div>
      <UserAuthContextProvider>
        <RouterProvider router={router} />
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
