import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase.js';
import './Login.scss';
import HANUMANJI from "../../images/logo.webp";
import axios from 'axios';

function YourNewComponent() {
  const navigate = useNavigate();

  const googleSignIn = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      console.log("Logged in user:", result.user);
      sendUserDetailsToBackend(result.user);
      navigate("/whatsappregister");
    } catch (error) {
      console.log(error.message);
    }
  };

  const sendUserDetailsToBackend = async (user) => {
    try {
      // Use axios to make a POST request to the backend
      await axios.post('http://localhost:5000/add_user', {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
      });

      console.log('User details sent to the backend successfully');
    } catch (error) {
      console.error('Error sending user details to the backend:', error);
    }
  };

  return (
    <div className="pageContainer">
      <div className="formContainer">
        <div className="formWrapper">
          <div className="logo">
            <img src={HANUMANJI} alt="Logo" className="logoImage" />
            <div className="logoText">
              <span className="maintitle" >Pavanputra</span>
            </div>
          </div>
          <div className="subtitle">
            <span>"Ready to take charge of your wellness journey?</span>
            <span>Sign up now and start your path to a healthier lifestyle with Pavanputra!"</span>
          </div>
          <div className="login">
            <button className="login-button" onClick={googleSignIn}>
              Log In 
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourNewComponent;
