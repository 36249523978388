import React from 'react';
import './SuccessPage.scss'; // Import the new SCSS file
import HANUMANJI from "../../images/logo.webp";

function SuccessPage() {
  // WhatsApp phone number
  const phoneNumber = '17162923337';

  // Function to open WhatsApp link in a new tab
  const openWhatsApp = () => {
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="success">
      <div className='container'>
            <div className="logoContainer">
              <div className="logo"><img src={HANUMANJI} alt="Logo" className="logoImage" /></div>
                <div className="logoText">
                  <span className="maintitle" >Pavanputra</span>
                </div>
            </div>
        <div className="description">
          <h2>Payment Successful</h2>
          <p>Thank you for your payment. Your order has been confirmed.</p>

          {/* Button to open WhatsApp link */}
          <button className="buttonStyle" onClick={openWhatsApp}>
            Use your ChatBot Now!!!
          </button>
      </div>
      </div>
    </div>
  );
}

export default SuccessPage;
