import React from 'react'
import "./Selecturplan.scss";
import ClearIcon from '@mui/icons-material/Clear';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';

function Selecturplan() {
  return (
    <div className="selecturplan">
        <div className="container">
            <div className="selecturplan-text">
                <span className="maintitle">Select your plan</span>
            </div>
            <div className="plan-cards">
              <div className='plan'>
              <span className="free">Free</span>
              <h1>FREE</h1>
              <ul>
                <li><CheckIcon /> Basic Free Chatbot</li>
                <li className="notavaliable"><ClearIcon /> Calorie Counter</li>
                <li className="notavaliable"><ClearIcon /> Video based Posture Corrector</li>
                <li><CheckIcon /> All time free</li>
                <li><CheckIcon /> 10 Messages/day</li>
                {/* <li><CheckIcon /> 75,000 Token Limit</li> */}
              </ul>
              <button>
                <Link className="link" to="/success">Get Started FREE</Link>
              </button>
              </div>

              <div className='plan1'>
                <span className="basic">Standard Version</span>
                <h1>$15/month</h1>
                <ul>
                  <li><CheckIcon /> Basic Chatbot</li>
                  <li className="notavaliable"><ClearIcon /> Calorie Counter</li>
                  <li className="notavaliable"><ClearIcon /> Video based Posture Corrector</li>
                  <li><CheckIcon /> Progress Tracking </li>
                  <li><CheckIcon /> No Message Limits</li>
                  {/* <li><CheckIcon /> 75,000 Token Limit</li> */}
                </ul>
                <button>
                  <Link className="link" to="/payment">Get Started </Link>
                </button>
              </div>

              <div className='plan2'>
                <span className="standard">Premium Version</span>
                <h1>$39/month</h1>
                <ul>
                  <li><CheckIcon /> Basic Chatbot</li>
                  <li><CheckIcon /> Calorie Counter</li>
                  <li className="notavaliable"><ClearIcon /> Video based Posture Corrector</li>
                  <li><CheckIcon /> Unlimited Days of Access</li>
                  <li><CheckIcon /> 1,000 Message Credits</li>
                  {/* <li><CheckIcon /> 210,000 Token Limit</li> */}
                </ul>
                <button>
                  <Link className="link" to="/payment">Get Started </Link>
                </button>
              </div>

              <div className='plan3'>
                <span className="premium">Premium Plus Version</span>
                <h1>$59/month</h1>
                <ul>
                  <li className="highlighting"><CheckIcon /> Premium Chatbot</li>
                  <li className="highlighting"><CheckIcon /> Calorie Counter</li>
                  <li className="highlighting"><CheckIcon /> Video based Posture Corrector</li>
                  <li><CheckIcon /> Unlimited Days of Access</li>
                  <li><CheckIcon /> 5,000 Message Credits</li>
                  {/* <li><CheckIcon /> 810,000 Token Limit</li> */}
                </ul>
                <button>
                  <Link className="link" to="/payment">Get Started </Link>
                </button>
              </div>
          </div>
          </div>
    </div>
  )
}

export default Selecturplan