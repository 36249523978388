import React from 'react';
import { Link } from 'react-router-dom';
import './Featured.scss'; // Import the SCSS stylesheet
import ASSET from "../../images/Asset 1.png";
function Final() {
  return (
    <div className="final">
      <div className="container">
        <div className="left-div">
          <h2 className="train1">TRAIN SMART</h2>
          <h2 className="train2">TRAIN</h2>
          <h2 className="pavanputra">PAVANPUTRA</h2>
          <div className="colored-line"></div>
          <p>
            Beyond the sweat and the strain, Smart choices set the gain. For every goal, big or ultra, Train Smart. Train Pavanputra.
          </p>
          <button className="getstartedbutton">
            <Link to="https://dashboard.pavan-putra.com" className="link">GET STARTED!</Link>
          </button>
        </div>
        <div className="right-div">
          <img src={ASSET} alt="workoutlogo" />
        </div>
      </div>
    </div>
  );
}

export default Final;
