import React from 'react';
import {Link} from "react-router-dom";
import "./Termsansconditions.scss";
import HANUMANJI from "../../images/logo.webp";

function Terms() {
  return (
    <div className="terms">
      <div className="container">
        <div className="logoContainer">
            <img src={HANUMANJI} alt="" className="logoImage" />
            <div className="logoText" >
              <span className="maintitle" data-text="Pavanputra">Pavanputra</span>
            </div>
        </div>

        <div className="content">
          <h1>Website Usage Terms and Conditions</h1>
          <h1>1. Acceptance of Terms</h1>
            <p>By using the Pavanputra LLC Chatbot, accessible via WhatsApp, you agree to abide by these Terms and Conditions. If you disagree with any part of these terms, please refrain from using the chatbot. These terms apply to all users, including visitors and registered users.</p>
          
          <h1>2. Fitness Information Disclaimer</h1>
            <p>
            The fitness-related responses provided by the Pavanputra LLC Chatbot are for informational purposes only. They do not constitute professional medical advice, diagnosis, or treatment. Always consult with a qualified healthcare provider before making any health or fitness decisions.
            </p>
          
          <h1>3. User Conduct and Content</h1>
            <p>You are responsible for the content of your interactions with the Pavanputra LLC Chatbot. You shall not use the chatbot to engage in any harmful, abusive, or unlawful activities.You understand that any information shared with the chatbot is at your own risk and may be used for improving the service</p>
          
          <h1>4. Accuracy and Reliability</h1>
            <p>The fitness-related information provided by the Pavanputra LLC Chatbot is based on general knowledge and patterns in the data it has been trained on.Pavanputra LLC does not guarantee the accuracy, completeness, or reliability of the responses, and users should verify information independently</p>
         
          <h1>5. User Privacy</h1>
            <p>
              Pavanputra LLC is committed to user privacy. The chatbot operates within the guidelines of the Privacy Policy, which outlines how user data is collected, used, and protected
            </p>
          
          <h1>6. Limitation of Liability</h1>
            <p>Pavanputra LLC and its operators shall not be liable for any direct, indirect, incidental, consequential, or special damages arising from the use or misuse of the chatbot.Users acknowledge that the chatbot's responses are automated and should not replace expert advice.</p>
            {/* <p>COMPANY NAME has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by COMPANY NAME Pvt Ltd of the site. The use of any linked website is at the user’s own risk.</p> */}
          
          <h1>7. Changes and Discontinuation</h1>
            <p>Pavanputra LLC reserves the right to modify, suspend, or discontinue the chatbot service, or any part of it, at any time without prior notice.The terms may be updated from time to time, and continued usage after changes indicates acceptance of the revised terms.</p>
            {/* <p>COMPANY NAME may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p> */}
          
          <h1>8. Intellectual Property</h1>
            <p>The Pavanputra LLC Chatbot, its name, design, and related content are protected by intellectual property laws and are the exclusive property of Pavanputra LLC</p>
          
          <h1>9. Governing Law and Jurisdiction</h1>
            <p>These terms are governed by the laws of the jurisdiction in which Pavanputra LLC is registered.Any disputes arising from the use of the chatbot shall be subject to the exclusive jurisdiction of the courts in the same jurisdiction.</p>
            {/* <p>Any claim related to COMPANY NAME Website shall be governed by the laws of India without regards to its conflict of law provisions.</p> */}
          
          <p>By using the Pavanputra LLC Chatbot, you confirm your understanding and agreement to these Terms and Conditions. If you do not agree, please refrain from using the chatbot</p>
        </div>

        <div  className="connectButton">
           <button className="connectbutton">
            <Link to="https://dashboard.pavan-putra.com" className="link">Connect Whatsapp</Link>
           </button>
        </div>

      </div>
    </div>
  )
}

export default Terms