import React from 'react';
import { Link } from 'react-router-dom';
import "./About.scss";
import HANUMANJI from "../../images/logo.webp";

function About() {
  return (
    <div className="about">
      <div className="container">
        <div className="logoContainer">
          <img src={HANUMANJI} alt="" className="logoImage" />
          <div className="logoText">
            <span className="maintitle" data-text="Pavanputra">Pavanputra</span>
          </div>
        </div>

        <div className="content">
          <h1>About Us</h1>
          <p>
            Welcome to Pavanputra LLC, your virtual fitness companion accessible via WhatsApp. Our mission is to provide you with reliable fitness-related information to assist you on your wellness journey.
          </p>

          <p>
            Our Chatbot is designed to answer your fitness queries and provide insights based on general knowledge and patterns in the data it has been trained on. Please remember that the information provided is not a substitute for professional medical advice.
          </p>

          <p>
            At Pavanputra, we are committed to your privacy and data security. We collect and use your information to enhance your user experience while ensuring your personal data is safeguarded.
          </p>

          <p>
            We are constantly working to improve our services and provide you with accurate and helpful information. Your feedback and suggestions are valuable to us.
          </p>
        </div>

        <div className="connectButton">
          <button className="connectbutton">
            <Link to="https://dashboard.pavan-putra.com" className="link">Connect Whatsapp</Link>
          </button>
        </div>

      </div>
    </div>
  );
}

export default About;
