import React from 'react';
import "./Wconnect.scss";
import HANUMANJI from "../../images/logo.webp";

function Wconnect() {
  const whatsapplink = "https://api.whatsapp.com/send?phone=17162923337"
  return (
    <div className="pageContainer">
      <div className="formContainer">
        <div className="formWrapper">
          <div className="logo">
            <img src={HANUMANJI} alt="Logo" className="logoImage" />
            <div className="logoText">
              <span className="maintitle">Pavanputra</span>
            </div>
          </div>
          <div className="subtitle">
            <span>Ready to experience the power of Pavanputra on WhatsApp?</span>
            <span>Click the "Connect WhatsApp" button below to embark on your wellness journey!</span>
          </div>
          <div className="Connect">
            <button className="Connect-button">
              <a href={whatsapplink} >Connect WhatsApp</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wconnect;