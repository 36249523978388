import React from 'react';
import { Link } from 'react-router-dom';
import "./Version.scss";
import HANUMANJI from "../../images/logo.webp";

function WhatsAppChatbotFeatureRelease() {
  return (
    <div className="whatsapp-chatbot-feature-release">
      <div className="container">
        <div className="logoContainer">
          <img src={HANUMANJI} alt="" className="logoImage" />
          <div className="logoText">
            <span className="maintitle" data-text="Pavanputra">Pavanputra</span>
          </div>
        </div>

        <div className="title">
          <h1 className="page-title-line1">FEATURE RELEASE DOC</h1>
          <h1  className="page-title-line2">v1.0</h1>
        </div>

        <div className="content">
          <h1>WhatsApp Chatbot Feature Release: Version 0.1</h1>
          <h2>Overview:</h2>
          <p>
            We are thrilled to introduce the Version 0.1 of our WhatsApp Chatbot. Our primary goal with this release is to provide users an efficient, AI-driven platform to address their fitness and bodybuilding queries directly through WhatsApp. This version integrates a powerful AI, offering nuanced guidance on fitness and bodybuilding.
          </p>

          <h2>Features:</h2>
          <ol>
            <li>
              <h3>AI-Enabled Fitness and Bodybuilding Chatbot:</h3>
              <p>
                <strong>Description:</strong> Our chatbot leverages the state-of-the-art AI models to understand user queries and provide accurate responses tailored to fitness and bodybuilding.
              </p>
              <p>
                <strong>Benefits:</strong> Users can receive professional-grade advice without visiting a gym or hiring a personal trainer. The chatbot is designed to understand a wide range of queries and provide solutions tailored to individual needs.
              </p>
            </li>

            <li>
              <h3>Free for the First 30 Days:</h3>
              <p>
                <strong>Description:</strong> Users can access and interact with the chatbot completely free for the initial 30 days post-registration.
              </p>
              <p>
                <strong>Benefits:</strong> This allows users to familiarize themselves with the chatbot's capabilities and realize its value without any financial commitment. A risk-free way to explore professional fitness advice.
              </p>
            </li>

            <li>
              <h3>Unrestricted Fitness and Bodybuilding Queries:</h3>
              <p>
                <strong>Description:</strong> The chatbot is designed to entertain any and all questions related to fitness and bodybuilding. Whether you are a beginner looking for basic tips or an advanced bodybuilder seeking nuanced guidance, our chatbot has got you covered.
              </p>
              <p>
                <strong>Benefits:</strong> Provides a one-stop solution for all fitness and bodybuilding related queries. Users don't have to sift through multiple sources or platforms to find answers.
              </p>
            </li>

            <li>
              <h3>Unlimited Chats:</h3>
              <p>
                <strong>Description:</strong> For the current version, there's no limitation on the number of chats or interactions a user can have with the chatbot.
              </p>
              <p>
                <strong>Benefits:</strong> Users can have extended conversations, ask follow-up questions, and dive deep into topics without worrying about reaching any chat limits. It provides an uninterrupted learning experience.
              </p>
            </li>
          </ol>

          <h2>Getting Started:</h2>
          <p>
            To start interacting with the chatbot, users simply need to save the dedicated number and send a "Hi" or "Start" message on WhatsApp. Following the initial greeting, the chatbot will guide the user on how to navigate and use its features effectively.
          </p>

          <h2>Feedback and Support:</h2>
          <p>
            User feedback is invaluable to us. As this is the initial release, we anticipate there might be areas of improvement. Users are encouraged to provide feedback on their experience, which will play a crucial role in refining subsequent versions of the chatbot.
          </p>
          <p>
            For support or more detailed information about any feature, users can reach out to <a href="mailto:info@pavan-putra.com" className="email-link">info@pavan-putra.com</a>.
          </p>

          <h2>Looking Forward:</h2>
          <p>
            While we're excited about the capabilities of Version 0.1, our team is already at work on the next iterations. Based on user feedback and evolving fitness trends, we will continuously enhance the chatbot to offer an even more seamless and enriching user experience.
          </p>
          <p>
            Stay tuned for more updates, and happy training!
          </p>
        </div>

        <div className="connectButton">
          <button className="connectbutton">
            <Link to="https://dashboard.pavan-putra.com" className="link">Connect Whatsapp</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WhatsAppChatbotFeatureRelease;
