import React from 'react'
import "./home.scss"
// import Navbar from '../../components/navbar/Navbar'
import Featured from '../../components/featured/Featured';
// import Footer from '../../components/footer/Footer'
import Block2 from "../../components/block2/Block2.jsx";
import Block1 from '../../components/block1/Block1.jsx';
import Block3 from '../../components/block3/Block3.jsx'; 
import Block4 from '../../components/Block4/Block4.jsx';

function home() {
  return (
    <div>
         <Featured/>
         <Block1/>
         <Block2/>
         <Block4/>
         <Block3/>
    </div>
  )
}

export default home